/* eslint-disable lines-between-class-members */
class BaseSearchFilter {
  /** @type {?number} */
  #accountNo = ''
  /** @type {number} */
  #companyId = 0
  /** @type {number} */
  #storeId = 0
  /** @type {number} */
  #warehouseId = 0
  /** @type {string} */
  #q = ''
  /** @type {!date} */
  #beginDate = null
  /** @type {!date} */
  #endDate = null
  /** @type {string} */
  #tenantType = 'SERVING' // SERVING, INDIVIDUAL, PARTNER default SERVING

  /** @returns {?number} */
  get accountNo() {
    return this.#accountNo
  }
  /** @param {?number} value */
  set accountNo(value) {
    this.#accountNo = value
  }

  /** @returns {number} */
  get companyId() {
    return this.#companyId
  }
  /** @param {number} value */
  set companyId(value) {
    this.#companyId = value
  }

  /** @returns {number} */
  get storeId() {
    return this.#storeId
  }
  /** @param {number} value */
  set storeId(value) {
    this.#storeId = value
  }

  /** @returns {number} */
  get warehouseId() {
    return this.#warehouseId
  }
  /** @param {number} value */
  set warehouseId(value) {
    this.#warehouseId = value
  }

  /** @returns {string} */
  get q() {
    return this.#q
  }
  /** @param {string} value */
  set q(value) {
    this.#q = value
  }

  /** @returns {!date} */
  get beginDate() {
    return this.#beginDate
  }
  /** @param {string} value */
  set beginDate(value) {
    this.#beginDate = value
  }

  /** @returns {!date} */
  get endDate() {
    return this.#endDate
  }
  /** @param {string|null} value */
  set endDate(value) {
    this.#endDate = value
  }

  /** @returns {string} */
  get tenantType() {
    return this.#tenantType
  }
  /** @param {string} value */
  set tenantType(value) {
    this.#tenantType = value
  }

  constructor() {
    this.#accountNo = this.accountNo
    this.#companyId = this.companyId
    this.#storeId = this.storeId
    this.#warehouseId = this.warehouseId
    this.#beginDate = this.beginDate
    this.#endDate = this.endDate
    this.#tenantType = this.tenantType
    this.#q = this.q
  }
}

export default BaseSearchFilter
