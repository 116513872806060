/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { exportDataGrid } from 'devextreme/excel_exporter'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import Pager from '@core/dev-extreme/utils/pager'

const options = {}
const pager = new Pager(options)

export default {
  data() {
    return {
      pageable: new Pager(),
    }
  },
  computed: {
    pagerOptions: {
      get() {
        return pager.toCreateOptions()
      },
    },
    setHeight() {
      return window.innerHeight / 1.2
    },
  },

  methods: {
    customizeColumns(columns) {
      columns.forEach(column => {
        column.cssClass = 'dx-column-vertical-middle'
      })
    },
    setPagination(context) {
      this.pageable.setContext(context)
    },
    onContentReady(e) {
    },
    onInitialized(e) {
      e.component.option('loadPanel.enabled', false)
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('ReceiveItems')
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            'Items.xlsx',
          )
        })
      })
      e.cancel = true
    },
  },
}
