import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  async fetchAllSortedItemsByBatchId(batchId, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.packageScan.Controller.findAllSortedItemsByBatchId(batchId, pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchAllSortedItemsByPackageScanId(packId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.packageScan.Controller.findAllSortedItemsByPackageScanId(packId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchAllInfoByBatchId(batchId, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.packageScan.Controller.findAllInfoByBatchId(batchId, pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchAllSortBatchesByUser(userId, pageable) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.packageScan.Controller.findAllSortBatchesByUser(userId, pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchByTracking(tracking) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.packageScan.Controller.findByTracking(tracking))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async changeCondition(params) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.packageScan.Controller.changeCondition(params))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async setSortCompleted(sortBatchId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.packageScan.Controller.setSortCompleted(sortBatchId))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async createMany(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.packageScan.Controller.createMany(), data)
        .then(response => {
          resolve({
            data: response,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async create(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.packageScan.Controller.create(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async update(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.packageScan.Controller.update(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async deleteById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.packageScan.Controller.delete(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async deleteMany(idList) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.packageScan.Controller.deleteMany(), idList)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async assignLocation(data) {
    const result = await axios.put(endpoints.packageScan.Controller.assignLocation(), data)
    return result
  },
  async getItemsByQuery(pageable, data) {
    const result = await axios.post(endpoints.packageScan.Controller.getItemsByQuery(pageable), data)
    return result
  },
}
