/* eslint-disable lines-between-class-members */
import BaseSearchFilter from './BaseSearchFilter'

export class PackageScanFilter extends BaseSearchFilter {
  /** @type {string} */
  #batchId = ''
  /** @type {boolean} */
  #includeAllBatches = false
  constructor() {
    super()
    this.#batchId = this.batchId
    this.#includeAllBatches = this.includeAllBatches
  }

  setDefaultFilters() {
    this.accountNo = ''
    this.companyId = 0
    this.storeId = 0
    this.warehouseId = 0
    this.beginDate = null
    this.endDate = null
    this.tenantType = 'SERVING'
    this.includeAllBatches = false
    this.q = ''
    this.batchId = 0
  }

  /** @returns {Object} filter object */
  getFilters() {
    const filter = {
      accountNo: this.accountNo,
      companyId: this.companyId,
      storeId: this.storeId,
      warehouseId: this.warehouseId,
      beginDate: this.beginDate,
      endDate: this.endDate,
      tenantType: this.tenantType,
      includeAllBatches: false,
      q: this.q,
      batchId: this.batchId,
    }
    return filter
  }

  /** @returns {string} */
  get batchId() {
    return this.#batchId
  }
  /** @param {string} value */
  set batchId(value) {
    this.#batchId = value
  }

  /** @returns {boolean} */
  get includeAllBatches() {
    return this.#includeAllBatches
  }
  /** @param {boolean} value */
  set includeAllBatches(value) {
    this.#includeAllBatches = value
  }
}
export default new PackageScanFilter()
