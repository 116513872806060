<template>
    <div class="card card-psh border">
        <div class="bg-light-primary rounded-top py-0 py-lg-1 px-1">
          <div class="psh-header mb-0 d-flex align-items-center">
            <div class="psh-title border-0">
              <i class="icon icon-psh-barcode display-4 text-responsive" />
            </div>
            <div class="d-flex flex-column">
              <h1 class="card-title mb-25">
                {{ decodeURIComponent(batchName) }}
              </h1>
              <p class="card-text mb-0">
                Suite No: {{ routerData.accountNo }}
              </p>
            </div>
            <div v-if="expectedCount !== 0" class="d-flex flex-grow-1 justify-content-end align-items-center">
              <h4 class="px-1 py-1 mr-1" :class="receivedCount < expectedCount ? '' : 'badge badge-danger'">
                {{ receivedCount }} / {{ expectedCount }}
              </h4>
              <div class="aling-self-center w-25 pt-1">
                <dx-progress-bar :max="expectedCount" :value="receivedCount" />
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <dx-data-grid
                id="packageScanGrid"
                ref="packageScanDataGridRef"
                width="inherit"
                height="calc(100vh - 270px)"
                :data-source="packageScanDataSource"
                :allow-column-reordering="true"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :show-column-lines="true"
                :show-row-lines="true"
                :show-borders="true"
                :hover-state-enabled="true"
                :row-alternation-enabled="false"
                :customize-columns="customizeColumns"
                @option-changed="onOptionChanged"
                @initialized="onInitialized"
                @content-ready="onContentReady"
                @editor-preparing="onEditorPreparing"
                @toolbar-preparing="onToolbarPreparing"
              >
              <dx-sorting mode="single" />
              <dx-selection
                show-check-boxes-mode="always"
                select-all-mode="page"
                :allow-select-all="true"
                :width="10"
                mode="multiple"
              />
              <template #toolbarFilters></template>
              <dx-remote-operations :paging="true" :sorting="true" :filtering="false" />
              <dx-toolbar>
                <dx-item location="before" template="trackingNumberTemplate" class="d-none sm-block" />
                <dx-item location="before" template="conditionSelectionTemplate" />
                <dx-item location="after" name="searchPanel" />
                <!-- <dx-item location="after" template="refreshButtonTemplate" /> -->
              </dx-toolbar>
              <!-- <template #refreshButtonTemplate>
              </template> -->
              <template #trackingNumberTemplate>
                <div class="input-group">
                  <input ref="trackingNumberRef" v-model="packageScanForm.tracking" type="text" class="form-control" placeholder="Tracking Number" aria-describedby="button-add-action" @keydown.enter="createPackageScan($event)">
                  <div class="input-group-append">
                    <button id="button-add-action" class="btn btn-primary" type="button" @click="createPackageScan($event)">
                      Add
                    </button>
                  </div>
                </div>
              </template>
              <template #conditionSelectionTemplate>
                <div>
                  <div class="btn-group" role="group" aria-label="Basic example">
                    <button v-for="button in conditionOptions" :key="button.name" type="button" class="btn" :class="packageScanForm.condition === button.value ? setColor(button.value) : 'btn-secondary'" @click="setCondition(button.value)">
                      {{ button.name }}
                    </button>
                  </div>
                </div>
              </template>
              <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
              <dx-column data-field="batchId" :visible="hideBatchName" :show-in-column-chooser="false" />
              <dx-column data-field="tracking" caption="Tracking" alignment="left" width="100%" :min-width="200" />
              <dx-column data-field="clientAccNo" caption="Acc #" />
              <dx-column data-field="companyName" />
              <dx-column data-field="q" :visible="false" />
              <dx-column data-field="condition" caption="Condition" alignment="left" cell-template="conditionTemplate" />
              <dx-column v-if="hasPermission" data-field="warehouseLocationName" caption="Location" alignment="left" cell-template="locationCell" :width="250" />
              <template #locationCell="{data}">
                <div>
                  <location-setter :row="data" @emit-location-updated="onRowChanged" />
                </div>
              </template>
              <dx-column data-field="creationTime" caption="Creation Time" data-type="date" />
              <dx-column data-field="scannedBy" />
              <template #conditionTemplate="{ data }">
                <div
                  class="badge badge-pill text-capitalize px-1"
                  :class="getConditionFormatted(data.value)"
                >
                {{ data.value }}
                </div>
              </template>
              <dx-column v-if="hasPermission" data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="120" :fixed="true" fixed-position="left" />
              <template #itemActions="{data}">
                <div>
                  <dx-util-button
                    type="aqua" icon="icon icon-feather-eye"
                    class="mr-half" hint="See package content"
                    @click="showPackageContent(data)"
                  />
                  <dx-util-button
                    type="warning" icon="bi bi-pencil-square"
                    class="mr-half" hint="Edit tracking"
                    @click="editItem(data)"
                  />
                  <dx-util-button
                    type="danger" icon="bi bi-trash"
                    hint="Delete tracking"
                    @click="deleteItem(data)"
                  />
                </div>
              </template>
              <dx-paging :page-size="pagerOptions.pageSize" />
              <dx-pager
                :show-info="pagerOptions.showInfo"
                :allowed-page-sizes="pagerOptions.allowedPageSizes"
                :show-navigation-buttons="pagerOptions.showNavigationButtons"
                :show-page-size-selector="pagerOptions.showPageSizeSelector"
                :visible="pagerOptions.visible"
              />
              </dx-data-grid>
            </div>
        </div>
        <div>
          <!--Begin:: Create Or Update Form-->
          <dx-util-popup
            ref="packageScanPopupRef"
            :show-close-button="true"
            :drag-enabled="false"
            :close-on-outside-click="false"
            :show-title="true"
            width="30vw"
            height="auto"
            title="Package Scan Form"
            @hidden="onHiddenPackageScan"
          >
            <dx-util-position at="center" my="center" />
            <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
            <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonOptions" />
            <form method="post" @submit="handleSubmit">
              <dx-util-form ref="packageScanFormRef" :form-data="packageScanForm" :col-count="1" :show-colon-after-label="true" label-location="top" validation-group="packageScanData">
                <dx-util-item data-field="tracking" editor-type="dxTextBox" :editor-options="trackingEditorOptions" />
                <dx-util-item data-field="accountNo" editor-type="dxNumberBox" :editor-options="accountNoEditorOptions" :label="{text: 'Account / Suite Number'}" />
                <dx-util-item data-field="condition" editor-type="dxSelectBox" :editor-options="conditionEditorOptions" />
                <dx-util-item data-field="notes" editor-type="dxTextArea" />
              </dx-util-form>
            </form>
          </dx-util-popup>
          <!--End:: Create Or Update Form-->
        </div>
        </div>
        <div>
          <package-scan-items :component-id="packageScanItemsComponentId" :company-id="companyId" :tracking="selectedTracking" />
        </div>
        <div>
          <location-multiple-setter :component-id="locationMultipleSetterCid" :record-ids="selectedRecordIds" @emit-location-updated="onRowChanged" />
        </div>
    </div>
</template>

<script>
/* eslint-disable no-param-reassign */

import GridBase from '@core/dev-extreme/mixins/grid/base'
import packageScanService from '@/http/requests/inbound/packageScanService'
import { Notify, TruncateTracking } from '@robustshell/utils/index'
import { DxProgressBar } from 'devextreme-vue/progress-bar'
import PackageScanFilter from '@/http/models/search-filters/PackageScanFilter'
import { v4 as uuidv4 } from 'uuid'
import { packageScanDataSource } from './packageScanStore'
import LocationSetter from './components/LocationSetter.vue'
// import packageScan from '@/http/endpoints/inbound/packageScan'

const conditionOptions = [
  { name: 'OK', value: 'ok', icon: 'icon bi-clipboard-check' },
  { name: 'Damaged', value: 'damaged', icon: 'icon bi-clipboard-x' },
]

export default {
  components: {
    DxProgressBar,
    'package-scan-items': () => import('./components/PackageScanItems'),
    'location-setter': LocationSetter,
    'location-multiple-setter': () => import('./components/LocationMultipleSetter.vue'),
  },
  mixins: [GridBase],
  data: () => ({
    selectedRecordIds: [],
    locationMultipleSetterCid: '',
    hideBatchName: true,
    includeAllBatches: false,
    packageScanDataSource,
    packageScanForm: {
      id: null,
      batchId: '',
      tracking: '',
      condition: 'ok',
      accountNo: null,
      clientAccNo: null,
      notes: '',
    },
    conditionOptions,
    expectedCount: 0,
    receivedCount: 0,
    routerData: {
      id: '',
      accountNo: '',
      batchId: '',
      batchName: '',
      status: '',
    },
    selectedTracking: '',
    packageScanItemsComponentId: '',
    query: '',
  }),
  computed: {
    batchId() {
      return this.routerData.batchId
    },
    companyId() {
      const companyId = this.$route.query.companyId
      return parseInt(companyId, 10)
    },
    batchName() {
      return this.routerData.batchName
    },
    batchStatus() {
      return this.routerData.status
    },
    dataGrid() {
      return this.$refs.packageScanDataGridRef.instance
    },
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    packageScanPopup() {
      const popup = this.$refs.packageScanPopupRef.instance
      return popup
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleSubmit(e.event)
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.packageScanPopup.hide()
        },
      }
    },
    trackingEditorOptions() {
      return {
        placeholder: 'Enter Tracking',
        readOnly: true,
      }
    },
    accountNoEditorOptions() {
      return {
        placeholder: 'Account No',
        readOnly: true,
      }
    },
    conditionEditorOptions() {
      return {
        items: this.conditionOptions,
        displayExpr: 'name',
        valueExpr: 'value',
        placeholder: 'Select Condition',
        valueChangeEvent: 'keyup',
      }
    },
  },
  created() {
    const {
      id, accountNo, batchId, batchName, status,
    } = this.$route.query
    this.routerData.id = parseInt(id, 10)
    this.routerData.accountNo = parseInt(accountNo, 10)
    this.routerData.batchId = batchId
    this.routerData.batchName = batchName
    this.routerData.status = status
  },
  mounted() {
    this.hideBatchName = false
    this.loadData()
  },
  methods: {
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageIndex') {
        this.pageIndex = e.value
      }
      this.pageSize = this.$refs.packageScanDataGridRef.instance.pageSize()
    },
    onEditorPreparing(e) {
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.query = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.query = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.query = ''
            this.searchByFilter()
          }
        }
      }
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.length = 0
      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })
      toolbarItems.push({
        widget: 'dxButton',
        visible: this.hasPermission,
        options: {
          text: 'Set Location',
          type: 'success',
          hint: 'Set Locations',
          onClick: () => {
            // önce uyar en az bir tane seç diye
            const data = this.dataGrid.getSelectedRowsData()
            if (data.length === 0) {
              Notify.warning('You should at least select one record')
              return
            }
            this.selectedRecordIds = data.map(el => el.id)
            this.locationMultipleSetterCid = uuidv4()
          },
        },
        location: 'after',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxCheckBox',
        cssClass: 'd-flex align-self-center',
        options: {
          text: 'All Batches',
          hint: 'Check to search in all batches',
          value: false,
          onValueChanged: c => {
            const that = this
            that.includeAllBatches = c.value
            if (c.value) {
              that.searchByFilter()
            } else {
              that.hideBatchName = false
              that.searchByFilter()
            }
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          hint: 'Reload Data',
          onClick: () => {
            this.hideBatchName = false
            this.reloadData()
          },
        },
        location: 'after',
      })
    },
    onRowChanged() {
      this.selectedRecordIds = []
      this.searchByFilter()
    },
    getConditionFormatted(status) {
      if (status.toLowerCase() === 'ok') return 'badge-success'
      if (status.toLowerCase() === 'damaged') return 'badge-danger'
      return 'badge-primary'
    },
    loadData() {
      const filters = PackageScanFilter.getFilters()
      filters.batchId = this.routerData.id
      filters.accountNo = this.routerData.accountNo
      filters.includeAllBatches = this.includeAllBatches
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.load()
    },
    reloadData() {
      const filters = PackageScanFilter.getFilters()
      filters.batchId = this.routerData.id
      filters.includeAllBatches = this.includeAllBatches
      if (this.includeAllBatches && this.query) {
        this.hideBatchName = true
        filters.batchId = null
      }
      filters.accountNo = this.routerData.accountNo
      filters.q = this.query
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      this.dataGrid.clearFilter('search')
      dataSource.reload()
    },
    searchByFilter() {
      const filters = PackageScanFilter.getFilters()
      filters.batchId = this.routerData.id
      filters.includeAllBatches = this.includeAllBatches
      if (this.includeAllBatches && this.query) {
        this.hideBatchName = true
        filters.batchId = null
      }
      filters.accountNo = this.routerData.accountNo
      filters.q = this.query
      const dataSource = this.dataGrid.getDataSource()
      dataSource.searchValue(filters)
      dataSource.reload()
    },
    showPackageContent(e) {
      const tracking = e.data.tracking
      this.selectedTracking = tracking
      this.packageScanItemsComponentId = uuidv4()
    },
    editItem(e) {
      const data = e.row.data
      this.packageScanForm.clientAccNo = data.clientAccNo
      this.packageScanForm.accountNo = data.clientAccNo
      this.packageScanForm.condition = data.condition
      this.packageScanForm.id = data.id
      this.packageScanForm.tracking = data.tracking
      this.packageScanForm.notes = data.notes
      this.packageScanPopup.show()
    },
    deleteItem(e) {
      const id = e.row.data.id
      if (this.batchStatus === 'complete') {
        Notify.warning('This batch is completed. You cannot take any modification action!')
        return
      }
      this.$swal({
        title: 'Are you sure you want to delete this Package record?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          packageScanService.deleteById(id).then(res => {
            this.receivedCount = res.data.body.receivedCount
            this.reloadData()
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Package record has been deleted.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        }
      })
    },
    createPackageScan(e) {
      if (!this.packageScanForm.tracking) {
        return
      }
      const sanitizedTracking = TruncateTracking(this.packageScanForm.tracking)
      this.packageScanForm.tracking = sanitizedTracking
      this.packageScanForm.clientAccNo = this.routerData.accountNo
      this.packageScanForm.batchId = this.routerData.id
      packageScanService.create(this.packageScanForm)
        .then(res => {
          this.expectedCount = res.data.expectedCount
          this.receivedCount = res.data.receivedCount
          this.packageScanForm.condition = 'ok'
          this.packageScanForm.tracking = ''
          this.reloadData()
          this.$refs.trackingNumberRef.focus()
        })
    },
    handleSubmit() {
      if (this.batchStatus === 'complete') {
        Notify.warning('This batch is completed. You cannot take any modification action!')
        return
      }
      const form = this.$refs.packageScanFormRef.instance
      const formValidation = form.validate()
      if (formValidation.isValid) {
        const sanitizedTracking = TruncateTracking(this.packageScanForm.tracking)
        this.packageScanForm.tracking = sanitizedTracking
        this.packageScanForm.batchId = this.routerData.id
        this.packageScanForm.clientAccNo = this.packageScanForm.accountNo
        packageScanService.update(this.packageScanForm).then(() => {
          this.reloadData()
          this.packageScanForm.condition = 'ok'
          this.packageScanPopup.hide()
        })
      }
    },
    onHiddenPackageScan() {
      const form = this.$refs.packageScanFormRef.instance
      form.resetValues()
      this.packageScanForm.condition = 'ok'
    },
    setCondition(condition) {
      this.packageScanForm.condition = condition
    },
    setColor(condition) {
      if (condition === 'ok') return 'btn-success'
      if (condition === 'damaged') return 'btn-danger'
      return 'btn-primary'
    },
  },
}
</script>

<style lang="scss">
#packageScanGrid {
  .dx-datagrid-search-panel {
    margin-left: 0px !important;
  }
}

</style>
