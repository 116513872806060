import * as R from 'ramda'
import { ObjectToQueryString } from '@robustshell/utils/index'

export default class Pager {
  defaultOptions = {
    pageSize: 25, // take
    allowedPageSizes: [25, 50, 100, 150, 200, 250],
    showInfo: true,
    showNavigationButtons: true,
    showPageSizeSelector: true,
    visible: true,
    staticPageSize: 200,
  }

  constructor(options, sortArr = undefined) {
    this.sortArray = (sortArr && sortArr.length > 0) ? [...sortArr] : [{ selector: 'id', desc: true }]
    this.defaultOptions = Object.assign(this.defaultOptions, options)
    this.size = this.defaultOptions.pageSize
    this.page = 0
    this.staticPageable = `page=0&size=${this.defaultOptions.staticPageSize}`
  }

  setPageNumber(skip, take) {
    if (R.is(Number, skip) && skip >= 0 && R.is(Number, take) && take > 0) {
      this.page = parseInt((((skip - 1) / take) + 1), 10)
      this.size = take
    }
  }

  toCreatePageable() {
    return ObjectToQueryString({ page: this.page, size: this.size })
  }

  toCreateOptions() {
    return {
      pageSize: this.defaultOptions.pageSize,
      allowedPageSizes: this.defaultOptions.allowedPageSizes,
      showInfo: this.defaultOptions.showInfo,
      showNavigationButtons: this.defaultOptions.showNavigationButtons,
      showPageSizeSelector: this.defaultOptions.showPageSizeSelector,
      visible: this.defaultOptions.visible,
    }
  }

  setSortOrder(arr) {
    if (arr && arr.length > 0) {
      this.sortArray.splice(0, this.sortArray.length)
      arr.forEach(element => {
        const field = element.selector.replace(/([a-z])([A-Z])/gm, '$1_$2').toLowerCase()
        const order = element.desc ? 'desc' : 'asc'
        this.sortArray.push(field)
        this.sortArray.push(order)
      })
    }
  }

  setSortQuery(arr) {
    if (arr && arr.length > 0) {
      this.sortArray.splice(0, this.sortArray.length)
      arr.forEach(element => {
        const field = element.selector // Keep the camelCase format
        const order = element.desc ? 'desc' : 'asc'
        this.sortArray.push(field)
        this.sortArray.push(order)
      })
    }
  }

  // for camelCase pageable sort, used with toCreateSortable method
  addSortOrder(arr) {
    this.sortArray = []
    arr.forEach(a => this.sortArray.push(a))
  }

  toCreateSortOrder() {
    return `${this.sortArray.toString()}`
  }

  // camelCase pageable sort
  toCreateSortable() {
    let queryString = ''
    this.sortArray.forEach(obj => {
      const sortString = `sort=${obj.selector},${obj.desc ? 'desc' : 'asc'}`
      queryString += `&${sortString}`
    })
    return queryString
  }
}
